import { UiButton, UiForm, message, UiModal, UiSpinner } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useCallback } from 'react';

import { GetWidgetStorage } from '@vkph/common/store/pages';
import { WidgetDataNavigation, WidgetDefaultDataNavigation } from '@vkph/modules/types/navigation';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

import { NavigationMenuType } from '../menu/NavigationMenu';
import styles from './NavigationEdit.scss';
import { SectionsList } from './sections-list/SectionsList';

type Props = {
  onClose: () => void;
  navigation: NavigationMenuType;
  navigationWidgetStorage: GetWidgetStorage<WidgetDataNavigation>;
};

export type NavigationEditSectionsForm = { sections: WidgetDefaultDataNavigation[] };

export const NavigationEdit: FC<Props> = (props) => {
  const { navigation, onClose, navigationWidgetStorage } = props;
  const { widgetId, defaultMenu: sections } = navigation;
  const [form] = UiForm.useForm<NavigationEditSectionsForm>();
  const { updateWidgetEffect } = navigationWidgetStorage;
  const navigationWidgetData = useStore(navigationWidgetStorage.storage.store);
  const savePending = useStore(updateWidgetEffect.pending);

  const onSaveSuccess = useCallback(() => {
    onClose();
    message.success('Сохранено');
  }, [onClose]);

  const onSaveEdit = () => {
    const { sections: data } = form.getFieldsValue();

    const attributes = navigationWidgetData.data?.attributes;

    updateWidgetEffect({
      widgetId,
      attributes: { ...attributes, defaultData: data },
    })
      .then(onSaveSuccess)
      .catch((e) => message.error(e.response?.data?.detail?.toString() || 'Ошибка сохранения'));
  };

  return (
    <>
      <UiModal.Header className={styles.navigationEdit__header}>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Настройка меню')} />
      </UiModal.Header>

      <UiModal.Content>
        <UiSpinner spinning={savePending}>
          <UiForm
            form={form}
            name="navigation-edit-sections"
            initialValues={{ sections }}
            className={styles.navigationEdit__formContent}
            onFinish={onSaveEdit}
          >
            <SectionsList />
          </UiForm>
        </UiSpinner>
      </UiModal.Content>

      <UiModal.Footer className={styles.navigationEdit__footer}>
        <UiButton size="large" type="primary" label="Сохранить" onClick={form.submit} loading={savePending} />
        <UiButton size="large" type="tertiary" label="Отмена" onClick={onClose} />
      </UiModal.Footer>
    </>
  );
};
