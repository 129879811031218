import { NewsList, newsFeedStorage } from '@vkph/components';
import { UiButton, UiCheckboxValueType, message } from '@vkph/ui';
import { useStoreMap } from 'effector-react';
import React, { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAbstractStorage } from '@vkph/common/hooks';
import { newsCategoriesStore } from '@vkph/common/store/news';
import { getWidgetStorage } from '@vkph/common/store/pages';
import { CategoryId, WidgetModel } from '@vkph/common/types/models';
import { RouteNames, getErrorResponseMessage, getRoutePath, generateSelectOptions } from '@vkph/common/utils';

import styles from './NewsWidget.scss';

type NewsWidgetData = {
  filteredValues: CategoryId[];
};

type NewsWidgetStorage = WidgetModel<NewsWidgetData>;

interface Props {
  widget: WidgetModel;
}

export const NewsWidget: FC<Props> = (props) => {
  const { widget } = props;
  const navigate = useNavigate();
  const onNavigateMainNews = useCallback(() => navigate(getRoutePath(RouteNames.News)), []);

  const { storage: widgetStorage, updateWidgetEffect } = useMemo(
    () =>
      getWidgetStorage<NewsWidgetStorage>({
        id: widget.id,
      }),
    [],
  );

  const { data: newsCategories } = useAbstractStorage(newsCategoriesStore.storage, {
    autoFetchAndRefetch: ({ fetchedAtLeastOnce }) => !fetchedAtLeastOnce,
  });

  const { data: newsWidget } = useAbstractStorage(widgetStorage, {
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
    resetStoreOnUnmount: true,
  });

  const filterValues =
    newsWidget?.attributes?.data?.filteredValues || newsWidget?.attributes?.defaultData?.filteredValues || [];

  const filterMenuItems = useMemo(
    () =>
      newsCategories
        ? generateSelectOptions(Object.values(newsCategories), { valuePath: 'id', labelPath: 'name' })
        : [],
    [newsCategories],
  );

  const onChangeFilter = (values: UiCheckboxValueType[]) =>
    updateWidgetEffect({
      widgetId: widget.id,
      attributes: {
        ...newsWidget?.attributes,
        data: { filteredValues: values },
      },
    }).catch((e) => message.error(getErrorResponseMessage(e, 'Не удалось применить фильтры')));

  const isNewsFeed = useStoreMap({
    store: newsFeedStorage.storage.store,
    keys: ['data'],
    fn: ({ data }) => Boolean(data.length),
  });

  const categoryIds = useStoreMap({
    store: widgetStorage.store,
    keys: ['data'],
    fn: ({ data }) => data?.attributes?.data?.filteredValues?.join(),
  });

  return (
    <NewsList
      title="Новости"
      filter={{ value: filterValues, items: filterMenuItems, onChange: onChangeFilter }}
      fetchParams={{ categoryIds }}
    >
      {isNewsFeed && (
        <UiButton
          block
          size="large"
          type="tertiary"
          label="Все новости"
          className={styles.newsWidget__button}
          onClick={onNavigateMainNews}
        />
      )}
    </NewsList>
  );
};
