import { fileStorageContextRouteMap, getFileStorageRouteParams } from '@vkph/common/store/filestorage';
import { isCommentContentType, isCommentSomeOfContentTypes } from '@vkph/common/types/guards';
import {
  CMSPageModel,
  CMSViewType,
  CommentAlbumImageEntryModel,
  CommentContentTypes,
  CommentDetailListModel,
  CommentFileVersionEntryModel,
  FileStorageListEntryModel,
  MicropostModel,
  NewsArticle,
  PostBlogModel,
  ProjectModel,
  ProjectsTasksModel,
  UserProfileModel,
} from '@vkph/common/types/models';
import { RouteNames, getFullNameWithoutPatronymic, getRoutePath } from '@vkph/common/utils';

const getFileRoutePath = (file: FileStorageListEntryModel) => {
  if (!file.context) {
    return '';
  }

  return getRoutePath(fileStorageContextRouteMap[file.context.service], {
    ...getFileStorageRouteParams(file),
    fileId: file.id,
  });
};

type CommentLinkInfo = {
  title?: string;
  to?: string;
};

export const getCommentLinkInfo = (comment: CommentDetailListModel): CommentLinkInfo => {
  if (isCommentContentType<PostBlogModel>(comment, CommentContentTypes.Post)) {
    return { title: 'посту' };
  }

  if (isCommentContentType<MicropostModel>(comment, CommentContentTypes.Micropost)) {
    return { title: 'микропосту' };
  }

  if (isCommentContentType<NewsArticle>(comment, CommentContentTypes.NewsEntry)) {
    return { title: comment.data.title };
  }

  if (isCommentContentType<CMSPageModel>(comment, CommentContentTypes.Cms)) {
    return {
      title: comment.data.name,
      to: getRoutePath(RouteNames.CmsView, { type: CMSViewType.Page, slugId: comment.data.url }),
    };
  }

  if (
    isCommentSomeOfContentTypes<UserProfileModel>(comment, [
      CommentContentTypes.Gamification,
      CommentContentTypes.ProfileEntry,
    ])
  ) {
    return {
      title: getFullNameWithoutPatronymic(comment.data.fullName),
      to: getRoutePath(RouteNames.Profile, { id: comment.data.id }),
    };
  }

  if (isCommentContentType<CommentAlbumImageEntryModel>(comment, CommentContentTypes.AlbumImage)) {
    return {
      title: comment.data.blog.name,
      to: getRoutePath(RouteNames.GroupView, { id: comment.data.blog.slug || comment.data.blog.id }),
    };
  }

  if (isCommentContentType<ProjectModel>(comment, CommentContentTypes.Project)) {
    return { title: comment.data.title, to: getRoutePath(RouteNames.ProjectsView, { id: comment.data.id }) };
  }

  if (isCommentContentType<ProjectsTasksModel>(comment, CommentContentTypes.Task)) {
    return { title: comment.data.title, to: getRoutePath(RouteNames.Tasks) };
  }

  if (isCommentContentType<FileStorageListEntryModel>(comment, CommentContentTypes.File)) {
    return {
      title: comment.data.name,
      to: getFileRoutePath(comment.data),
    };
  }

  if (isCommentContentType<CommentFileVersionEntryModel>(comment, CommentContentTypes.FileVersion)) {
    return {
      title: comment.data.file.name,
      to: getFileRoutePath(comment.data.file),
    };
  }

  return {};
};
